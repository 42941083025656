import React from 'react'
import { Navigate } from 'react-router-dom'
import { useUser } from '@/features/user/hooks'
import { ROLE } from '@/features/users/types'
import { useFromPath } from '@/hooks'
import { PRIVAT_INITIAL_ROUTE } from '@/routes/const'
import { GUARD } from '../../guards'

interface IProps {
  children: React.ReactNode
  roles?: Array<string>
  guards?: Array<GUARD>

  [x: string]: any
}

export const RouteGuard: React.FC<IProps> = ({ children, roles, guards = [], ...props }) => {
  const from = useFromPath()

  const { user } = useUser()

  const withGuard = (type: GUARD) => {
    return guards.some((guard) => guard === type)
  }

  if (roles && user && !roles.includes(user.role)) {
    const path = PRIVAT_INITIAL_ROUTE
    return <Navigate to={path} state={{ from }} />
  }

  return <>{children}</>
}
