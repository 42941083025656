import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Card, Group, Text } from '@mantine/core'
import { Alert, Button, Modal } from '@/components/Elements'
import { TextAreaController } from '@/components/FormElements'
import { chatAPI } from '@/features/chat/api'
import { useUser } from '@/features/user/hooks'
import { useNotify } from '@/hooks'
import { useFormSubmit } from '@/hooks'
import { UploadControl } from './UploadControl'
import { validationSchema } from './validation'

export const Answer = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const { showNotification } = useNotify()

  const navigate = useNavigate()

  const [selectedList, setSelectedList] = useState<any>([])

  const { user } = useUser()

  const onSubmitSuccess = async (data: any) => {
    try {
      await chatAPI.sendAnswer(data)

      showNotification({
        type: 'success',
        message: t('send_successfully'),
      })

      navigate('/messages')
    } catch (e) {}
  }

  const methods = useForm({
    defaultValues: {
      content: '',
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    register,
    setValue,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: onSubmitSuccess,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const values = {
        ...data,
        id,
        attachments_ids: selectedList?.map((el: any) => el?.id) || [],
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  const handleDeleteFile = (item: any) => {
    const newList = selectedList.filter((el: any) => el?.id !== item?.id)
    setSelectedList(newList)
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <Group mb={'lg'}>
          <UploadControl handleSelected={setSelectedList} selectedList={selectedList} />
          {selectedList.map((el: any) => {
            return (
              <Card>
                <Text mb={'sm'}>
                  {t('file')} {el?.name}
                </Text>
                <Button
                  fullWidth
                  type={'button'}
                  variant={'default'}
                  size={'sm'}
                  onClick={() => handleDeleteFile(el?.id)}
                >
                  {t('delete')}
                </Button>
              </Card>
            )
          })}
        </Group>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextAreaController
            withAsterisk
            name={`content`}
            id={`content`}
            label={t('message')}
            placeholder={t('message')}
            mb={'md'}
            control={control}
          />

          <div>
            <Group>
              <Button loading={isSubmitting} type={'submit'}>
                {t('send')}
              </Button>
            </Group>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
