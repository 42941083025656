import axios from 'axios'

class ChatAPI {
  createChat = async (data: any) => {
    return axios.post(`api/conversations`, data)
  }

  sendAnswer = async (data: any) => {
    return axios.post(`api/conversations/${data?.id}/answer`, data)
  }

  getSpecialists = async (name: any) => {
    return axios.get(`api/users-specialists?filters[specialist.name]=${name}`)
  }

  getFile = async (id: any) => {
    return axios.get(`api/attachments/${id}`)
  }

  getConversations = async (page: number) => {
    if (window.location.pathname?.indexOf('archive') !== -1) {
      return axios.get(`api/user/conversations/archive?page=${page}`)
    }

    return axios.get(`api/user/conversations?page=${page}`)
  }

  getConversation = async (id: any) => {
    return axios.get(`api/conversations/${id}`)
  }

  getStats = async (id: any) => {
    return axios.get(`api/users/${id}/statistic`)
  }

  uploadFile = async (data: any) => {
    const formData = new FormData()
    formData.append('file', data)

    return axios.post(`api/upload-attachment`, formData)
  }

  doctorRead = async (id: any) => {
    return axios.patch(`api/conversations/${id}`, { is_read_by_doctor: true })
  }

  moveToArchiveSpecialist = async (id: any) => {
    return axios.patch(`api/conversations/${id}`, { is_archive_by_specialist: true })
  }

  moveToArchiveDoctor = async (id: any) => {
    return axios.patch(`api/conversations/${id}`, { is_archive_by_doctor: true })
  }

  moveToMessagesSpecialist = async (id: any) => {
    return axios.patch(`api/conversations/${id}`, { is_archive_by_specialist: false })
  }

  moveToMessagesDoctor = async (id: any) => {
    return axios.patch(`api/conversations/${id}`, { is_archive_by_doctor: false })
  }

  printed = async (id: any) => {
    return axios.patch(`api/conversations/${id}`, { is_printed: true })
  }

  specialistRead = async (id: any) => {
    return axios.patch(`api/conversations/${id}`, { is_read_by_specialist: true })
  }

  specialistsRoles = async () => {
    return axios.get(`api/specialists?filters[with_active_users]=true`)
  }

  getEmailTemplate = async (id: any) => {
    return axios.get(`api/conversations/${id}/pdf`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
    })
  }
}

export const chatAPI = new ChatAPI()
