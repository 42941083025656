import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Card, Group, Stepper, Text, Tooltip } from '@mantine/core'
import { Alert, Button, Modal } from '@/components/Elements'
import {
  SelectController,
  TextAreaController,
  TextInputController,
} from '@/components/FormElements'
import { chatAPI } from '@/features/chat/api'
import { useUser } from '@/features/user/hooks'
import { useNotify } from '@/hooks'
import { useFormSubmit } from '@/hooks'
import { formatDateTime } from '@/utils/date'
import { UploadControl } from './UploadControl'
import {
  avsCodeSchema,
  contentSchema,
  insuranceNumberSchema,
  insuranceSchema,
  recipientIdSchema,
  subjectSchema,
  validationSchema,
} from './validation'

export const NewChat = () => {
  const { t } = useTranslation()
  const { showNotification } = useNotify()

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [selectedList, setSelectedList] = useState<any>([])

  const { user } = useUser()

  const [rolesList, setRolesList] = useState<any>([])
  const [specialists, setSpecialists] = useState([])

  const loadRoles = async () => {
    try {
      setLoading(true)

      const { data } = await chatAPI.specialistsRoles()

      const rolesDataList = data?.data?.map((el: any) => {
        return {
          label: el?.name,
          value: el?.name,
        }
      })

      setRolesList(rolesDataList)
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  const [step, setStep] = useState(0)
  const nextStep = () => setStep((current) => (current < 3 ? current + 1 : current))
  const prevStep = () => setStep((current) => (current > 0 ? current - 1 : current))

  const onSubmitSuccess = async (data: any) => {
    try {
      const res = await chatAPI.createChat(data)

      showNotification({
        type: 'success',
        message: t('created_successfully'),
      })

      if (res?.data?.data?.id) {
        navigate(`/messages/${res?.data?.data?.id}?download=true`)
      } else {
        navigate('/messages')
      }
    } catch (e) {}
  }

  const methods = useForm({
    defaultValues: {
      subject: '',
      content: '',
      role: '',
      recipient_id: '',
      avs_code: '',
      insurance: '',
      insurance_number: '',
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    watch,
    getValues,
    register,
    setValue,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: onSubmitSuccess,
    setError,
  })

  const recipient_id = useWatch({
    control,
    name: 'recipient_id',
  })

  const specialistData = () => {
    const data: any = specialists?.find((item: any) => item?.value === recipient_id)

    return data?.label || '-'
  }

  const role = useWatch({
    control,
    name: 'role',
  })

  const subject = useWatch({
    control,
    name: 'subject',
  })

  const content = useWatch({
    control,
    name: 'content',
  })

  const avs_code = useWatch({
    control,
    name: 'avs_code',
  })

  const insurance = useWatch({
    control,
    name: 'insurance',
  })

  const insurance_number = useWatch({
    control,
    name: 'insurance_number',
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const values = {
        ...data,
        sender_id: `${user.id}`,
        attachments_ids: selectedList?.map((el: any) => el?.id) || [],
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  const handleLoadSpecialists = async () => {
    setValue('recipient_id', '')
    try {
      const res = await chatAPI.getSpecialists(getValues('role'))

      const specialistList = res?.data?.data?.map((el: any) => {
        let name = ''

        if (el?.first_name || el?.last_name) {
          name = `${el?.first_name || ''} ${el?.last_name || ''}`
        } else {
          name = `${el?.email || '-'}`
        }

        return {
          label: name,
          value: `${el?.id}`,
        }
      })

      setSpecialists(specialistList)
    } catch (e) {}
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'role' && getValues('role')) {
        handleLoadSpecialists()
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    loadRoles()
  }, [])

  const isDisabled = () => {
    if (step === 0) {
      return !recipientIdSchema.isValidSync(recipient_id)
    }

    if (step === 1) {
      return (
        !avsCodeSchema.isValidSync(avs_code) ||
        !insuranceSchema.isValidSync(insurance) ||
        !insuranceNumberSchema.isValidSync(insurance_number)
      )
    }

    if (step === 2) {
      return !contentSchema.isValidSync(content) || !subjectSchema.isValidSync(subject)
    }

    return false
  }

  const handleDeleteFile = (item: any) => {
    const newList = selectedList.filter((el: any) => el?.id !== item?.id)

    setSelectedList(newList)
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Card mt={'xl'}>
            <Stepper active={step} onStepClick={setStep} allowNextStepsSelect={false}>
              <Stepper.Step
                label={t('first_step')}
                description={t('choose_role_and_recipient')}
              ></Stepper.Step>
              <Stepper.Step
                label={t('second_step')}
                description={t('provide_patient_info')}
              ></Stepper.Step>
              <Stepper.Step
                label={t('third_step')}
                description={t('add_message_and_subject')}
              ></Stepper.Step>
            </Stepper>
          </Card>
          <Card mt={'xl'}>
            {step === 0 && (
              <>
                <SelectController
                  control={control}
                  name={'role'}
                  id={'role'}
                  withAsterisk
                  label={t('role')}
                  placeholder={t('role')}
                  data={rolesList || []}
                  my={'md'}
                />

                <SelectController
                  control={control}
                  name={'recipient_id'}
                  id={'recipient_id'}
                  withAsterisk
                  disabled={Boolean(!getValues('role'))}
                  label={t('to')}
                  placeholder={t('to')}
                  data={specialists || []}
                  my={'md'}
                />
              </>
            )}

            {step === 1 && (
              <>
                <TextInputController
                  name={`avs_code`}
                  id={`avs_code`}
                  withAsterisk
                  label={`${t('avs_code')}:`}
                  placeholder={t('avs_code')}
                  mb={'md'}
                  control={control}
                />

                <TextInputController
                  name={`insurance`}
                  id={`insurance`}
                  withAsterisk
                  label={`${t('insurance')}:`}
                  placeholder={t('insurance')}
                  mb={'md'}
                  control={control}
                />

                <TextInputController
                  name={`insurance_number`}
                  id={`insurance_number`}
                  withAsterisk
                  label={`${t('insurance_number')}:`}
                  placeholder={t('insurance_number')}
                  mb={'md'}
                  control={control}
                />
              </>
            )}

            {step === 2 && (
              <>
                <Group mb={'lg'}>
                  <UploadControl handleSelected={setSelectedList} selectedList={selectedList} />
                  {selectedList.map((el: any) => {
                    return (
                      <Tooltip label={el?.name}>
                        <Card maw={200}>
                          <Text mb={'sm'} truncate>
                            {t('file')} {el?.name}
                          </Text>
                          <Button
                            fullWidth
                            type={'button'}
                            variant={'default'}
                            size={'sm'}
                            onClick={() => handleDeleteFile(el)}
                          >
                            {t('delete')}
                          </Button>
                        </Card>
                      </Tooltip>
                    )
                  })}
                </Group>
                <TextInputController
                  name={`subject`}
                  id={`subject`}
                  withAsterisk
                  label={`${t('subject')}:`}
                  placeholder={t('subject')}
                  mb={'md'}
                  control={control}
                />
                <TextAreaController
                  withAsterisk
                  name={`content`}
                  id={`content`}
                  label={t('message')}
                  placeholder={t('message')}
                  mb={'md'}
                  control={control}
                />
              </>
            )}

            {step === 3 && (
              <Alert
                variant="filled"
                style={{
                  background:
                    'linear-gradient(to right, rgba(1, 98, 232, 1), rgba(1, 98, 232, 0.6) 100%',
                }}
                title={<Text size="lg">{t('question_preview')}</Text>}
              >
                <Text size={'md'}>{content}</Text>
                <Text mt={'md'} size={'xs'}>
                  {formatDateTime(new Date())}
                </Text>
                <Text size={'xs'}>
                  {t('from')}: {user?.email} {user?.first_name || ''} {user?.last_name || ''}
                </Text>
                <Text size={'xs'}>
                  {t('to')}: {specialistData()}
                </Text>
                <Text size={'xs'}>
                  {t('avs_code')}: {avs_code || '-'}
                </Text>
                <Text size={'xs'}>
                  {t('insurance')}: {insurance || '-'}
                </Text>
                <Text size={'xs'}>
                  {t('insurance_number')}: {insurance_number || '-'}
                </Text>

                <Group mt={'sm'}>
                  {selectedList.map((el: any) => {
                    return (
                      <Tooltip label={el?.name}>
                        <Card maw={200}>
                          <Text truncate>
                            {t('file')} {el?.name}
                          </Text>
                        </Card>
                      </Tooltip>
                    )
                  })}
                </Group>
              </Alert>
            )}
          </Card>

          <Group align="center" position="right" my="xl">
            <Button variant="default" type="button" disabled={step === 0} onClick={prevStep}>
              {t('back')}
            </Button>
            {step === 3 ? (
              <Button loading={isSubmitting} type={'button'} onClick={handleSubmit(onSubmit)}>
                {t('send')}
              </Button>
            ) : (
              <Button type="button" onClick={nextStep} disabled={isDisabled()}>
                {t('next')}
              </Button>
            )}
          </Group>
        </form>
      </FormProvider>
    </>
  )
}
