import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { Button, Modal } from '@/components/Elements'

interface IProps {
  visible: boolean
  children?: React.ReactNode
  message?: string | null
  confirmText?: React.ReactNode
  onConfirm: () => void
  onCancel?: () => void
  onClose: () => void
}

export const ConfirmModal = ({
  visible,
  children,
  message,
  confirmText,
  onConfirm,
  onCancel,
  onClose,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  message = message || t('please_confirm')

  const renderMessage = () => <Text weight={600}>{message}</Text>

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    } else {
      onClose && onClose()
    }
  }

  return (
    <>
      <Modal opened={visible} onClose={onClose} centered title={t('confirmation')} {...props}>
        <div className={'text-center'}>
          {children ? children : renderMessage()}

          <div className={'mt-1 w-full'}>
            <Button mt={'lg'} fullWidth onClick={onConfirm}>
              {confirmText ? confirmText : t('confirm')}
            </Button>

            <Button mt={'lg'} fullWidth variant="subtle" color="gray" onClick={handleCancel}>
              {t('cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
