import React from 'react'
import { Outlet } from 'react-router-dom'
import { AppShell } from '@mantine/core'
import { useToggle } from '@mantine/hooks'
import { Header } from './Header'
import { Navbar } from './Navbar'
import useStyles from './PanelLayout.styles'

export const PanelLayout = () => {
  const { classes } = useStyles()

  const [isNavbarDrawerOpened, toggleNavbarDrawerOpened] = useToggle([false, true])

  return (
    <>
      <AppShell
        padding="md"
        navbar={
          <Navbar
            drawerOpened={isNavbarDrawerOpened}
            onDrawerClose={() => toggleNavbarDrawerOpened(false)}
          />
        }
        header={<Header onDrawerOpen={() => toggleNavbarDrawerOpened(true)} />}
        classNames={classes}
      >
        <Outlet />
      </AppShell>
    </>
  )
}
