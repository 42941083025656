import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Chat } from '../chat'
import { Chats } from '../chats'

export const ChatsRoot = () => {
  return (
    <Routes>
      <Route index element={<Chats />} />
      <Route path={'/:id'} element={<Chat />} />
      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
