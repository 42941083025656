import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  BsArchive,
  BsBarChart,
  BsChatText,
  BsGear,
  BsPerson,
  BsPlusCircle,
  BsQuestionCircle,
} from 'react-icons/bs'
import { useUser } from '@/features/user/hooks'
import { ChatUnreadBadge } from '../Badges'
import { NavLink, NavLinkType } from '../NavLink'

interface IProps {
  onLinkClick: () => void
}

export const GeneralLinks = ({ onLinkClick }: IProps) => {
  const { t } = useTranslation()

  const { isDoctor, isSpecialist } = useUser()

  const links: Array<NavLinkType> = isDoctor()
    ? [
        {
          path: '/messages',
          label: t('messages'),
          icon: BsChatText,
          renderRightSection: ({ isActive }: { isActive: boolean }) => (
            <ChatUnreadBadge isActive={true} />
          ),
        },
        {
          path: '/new-message',
          label: t('messages_new'),
          icon: BsPlusCircle,
        },
        {
          path: '/archive',
          label: t('archive'),
          icon: BsArchive,
        },
        {
          path: '/settings',
          label: t('settings'),
          icon: BsGear,
        },
        {
          path: '/profile',
          label: t('profile'),
          icon: BsPerson,
        },
      ]
    : [
        {
          path: '/messages',
          label: t('messages'),
          icon: BsChatText,
          renderRightSection: ({ isActive }: { isActive: boolean }) => (
            <ChatUnreadBadge isActive={true} />
          ),
        },
        {
          path: '/archive',
          label: t('archive'),
          icon: BsArchive,
        },
        {
          path: '/settings',
          label: t('settings'),
          icon: BsGear,
        },
        {
          path: '/profile',
          label: t('profile'),
          icon: BsPerson,
        },
      ]

  return (
    <>
      {links.map((item) => {
        return <NavLink key={item.path} onClick={onLinkClick} {...item} />
      })}
    </>
  )
}
