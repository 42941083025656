import { uniqueId } from 'lodash'
import { makeAutoObservable, reaction } from 'mobx'
import { chatAPI } from '../api'

export class ChatStore {
  unread = 0
  uuid = ''

  constructor() {
    makeAutoObservable(this)
  }

  handleStats = async (id: any) => {
    try {
      const { data } = await chatAPI.getStats(id)
      this.uuid = uniqueId()

      if (data?.data?.count) {
        this.unread = data?.data?.count
      } else {
        this.unread = 0
      }
    } catch (e) {}
  }

  handleNewMessage = () => {
    this.unread = this.unread + 1
  }
}

export const chatStore = new ChatStore()
