import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Anchor, Group, Title } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { PasswordInputController, TextInputController } from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { authAPI } from '@/features/auth/api'
import { useNotify } from '@/hooks'
import { useFormSubmit } from '@/hooks'
import { FormTypes } from '@/types'

export type FormValues = {
  email: string
  password: string
  code: string
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
  onVerifyEmail: (email: string) => Promise<void>
  onVerifyCode: (email: string) => Promise<void>
}

export const LoginForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [codeTimer, setCodeTimer] = useState<null | number>(null)
  const [step, setStep] = useState<1 | 2>(1)

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
      code: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email('field.error.invalid').required('field.error.required'),
        code: Yup.string().required('field.error.required'),
        password: Yup.string()
          .required('field.error.required')
          .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
      })
    ),
  })

  const emailField = useWatch({
    control,
    name: 'email',
  })

  const passwordField = useWatch({
    control,
    name: 'password',
  })

  const [submittedEmail, setSubmittedEmail] = useState<string | null>(null)

  const {
    error: submitError,
    onSubmit: onFormSubmit,
    resetState: submitResetState,
  } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setSubmittedEmail(data.email)
    try {
      await onFormSubmit(data)
    } catch (error) {}
  }

  const codeTimerHandler = () => {
    setTimeout(() => {
      setCodeTimer((count) => (count || 1) - 1)
    }, 1000)
  }

  useEffect(() => {
    if (codeTimer) {
      codeTimerHandler()
    } else {
      setCodeTimer(null)
    }
  }, [codeTimer])

  const onSendCode = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      const email = getValues('email')
      if (!email) {
        showNotification({
          type: 'success',
          message: t('email-required'),
        })
        return
      }

      await props.onVerifyCode(email)

      setCodeTimer(60)

      showNotification({
        type: 'success',
        message: t('code-email-sent'),
      })
    } catch (err) {
      const serverError = err as FormTypes.ValidationErrors
      const message = serverError?.message || t('error')
      showNotification({
        type: 'error',
        message,
      })
    }
  }

  const onSendVerifyCredentials = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    try {
      await authAPI.verifyCredentials({ email: emailField, password: passwordField })
      setCodeTimer(60)
      clearErrors()
      setStep(2)
      showNotification({
        type: 'success',
        message: t('confirmation-email-sent'),
      })
    } catch (serverError: any) {
      const message = serverError?.response?.data?.message || t('error')
      showNotification({
        type: 'error',
        message,
      })
    }
  }

  const onSendVerifyEmail = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    submitResetState()
    clearErrors()

    if (submittedEmail) {
      try {
        await props.onVerifyEmail(submittedEmail)
        showNotification({
          type: 'success',
          message: t('confirmation-email-sent'),
        })
      } catch (err) {
        const serverError = err as FormTypes.ValidationErrors
        const message = serverError?.message || t('error')
        showNotification({
          type: 'error',
          message,
        })
      }
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        {submitError && submitError?.errors?.unverified_email && (
          <Title order={6} my={'md'}>
            <Anchor
              component={'a'}
              href={'#'}
              onClick={(e: React.MouseEvent<HTMLElement>) => onSendVerifyEmail(e)}
            >
              {t('send_confirmation_email_to')}
            </Anchor>
            {` ${submittedEmail}`}
          </Title>
        )}

        {step === 1 && (
          <>
            <TextInputController
              control={control}
              name={'email'}
              id={'email'}
              label={t('email')}
              placeholder={t('email')}
              mb={'md'}
            />
            <PasswordInputController
              control={control}
              name={'password'}
              id={'password'}
              label={t('password')}
              placeholder={t('password')}
              mb={'md'}
              translateParams={{ count: PASSWORD_MIN_LENGTH }}
            />{' '}
          </>
        )}

        {step === 2 && (
          <Group align={'center'} position={'apart'}>
            <TextInputController
              w={'50%'}
              control={control}
              name={'code'}
              id={'code'}
              placeholder={t('code')}
            />

            <Button w={'40%'} onClick={onSendCode} disabled={Boolean(codeTimer)} type={'button'}>
              {codeTimer ? (
                <>
                  {codeTimer} {t('sec')}
                </>
              ) : (
                t('send_code')
              )}
            </Button>
          </Group>
        )}

        <Group mt={'lg'} align={'center'} position={'right'}>
          <Title order={6} align={'right'}>
            <Anchor component={Link} to={'/forgot-password'}>
              {t('forgot_password')}
            </Anchor>
          </Title>
        </Group>

        {step === 1 && (
          <Button
            mt={'lg'}
            fullWidth
            type={'button'}
            onClick={onSendVerifyCredentials}
            disabled={
              !Yup.string()
                .email('field.error.invalid')
                .required('field.error.required')
                .isValidSync(emailField) ||
              !Yup.string()
                .required('field.error.required')
                .min(PASSWORD_MIN_LENGTH, 'field.error.password.length')
                .isValidSync(passwordField)
            }
          >
            {t('next')}
          </Button>
        )}

        {step === 2 && (
          <Button mt={'lg'} fullWidth type={'submit'} loading={isSubmitting}>
            {t('login')}
          </Button>
        )}
      </form>
    </>
  )
}
