import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Box } from '@mantine/core'
import { Alert, Button, GeneralCard } from '@/components/Elements'
import { CheckboxFieldController } from '@/components/FormElements'
import { profileClientUpdatePA } from '@/features/profile/store'
import { useUser } from '@/features/user/hooks'
import { useFormSubmit } from '@/hooks'
import { useNotify } from '@/hooks'

export const SettingsContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmitForm = async (values: any) => {
    await dispatch(profileClientUpdatePA.request(values))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })
  }

  const { user } = useUser()

  const { is_notify, ...restUserData } = user || {}

  const defaultValues = {
    ...restUserData,
    is_notify: is_notify || false,
  }

  const methods = useForm({
    defaultValues,
  })

  const {
    handleSubmit,
    setError,
    control,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: onSubmitForm,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (error) {}
  }

  return (
    <GeneralCard title={t('settings')}>
      <Box mt={'md'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {submitError && (
            <Alert type={'error'} mb={'sm'}>
              {submitError?.message || t('error')}
            </Alert>
          )}
          <CheckboxFieldController
            control={control}
            name={'is_notify'}
            id={'is_notify'}
            label={<>{t('use_notify')}</>}
            mb={'md'}
          />
          <Button mt={'lg'} type={'submit'} loading={isSubmitting}>
            {t('save')}
          </Button>
        </form>
      </Box>
    </GeneralCard>
  )
}
