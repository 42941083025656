import axios from 'axios'
import { CreateTranslation } from './dto'

class TranslationsAPI {
  getTranslationsSingle = async (params: any) => {
    return axios.get(`api/translations/single`, {
      params,
    })
  }

  getTranslations = async (params: any) => {
    return axios.get(`api/admin/translations`, {
      params,
    })
  }

  createTranslation = async (data: CreateTranslation) => {
    return axios.post(`api/admin/translations`, data)
  }
}

export const translationsAPI = new TranslationsAPI()
