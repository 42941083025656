import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Badge, Box, Card, Group, Text } from '@mantine/core'
import {
  Alert,
  Button,
  ButtonLink,
  MetaPagination,
  OverlayDataShower,
  TableView,
} from '@/components/Elements'
import { PageContent, PageHeader } from '@/components/Layouts'
import { chatAPI } from '@/features/chat/api'
import { useUser } from '@/features/user/hooks'
import { ROLE } from '@/features/users/types'
import { langHelper } from '@/helpers'
import { useNotify } from '@/hooks'
import { formatDateTime } from '@/utils/date'
import { chatStore } from '../../store/chatStore'

const langs = langHelper.general.getLanguages().reverse()

export const Chats = observer(() => {
  const { t } = useTranslation()
  const { showNotification } = useNotify()

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState<any>(null)
  const [page, setPage] = useState(1)

  const navigate = useNavigate()

  const { user } = useUser()
  const handleLoad = async () => {
    try {
      setLoading(true)

      const { data } = await chatAPI.getConversations(page)

      setList(data?.data)
      setMeta(data?.meta)
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  const [columns, setColumns] = useState<any[]>([])

  const isReadStatus = (data: any) => {
    if (user.role === ROLE.Doctor && Boolean(data?.is_read_by_doctor)) return true
    if (user.role === ROLE.Specialist && Boolean(data?.is_read_by_specialist)) return true

    return false
  }

  const handleArchive = async (id: any) => {
    try {
      if (user.role === ROLE.Doctor) {
        await chatAPI.moveToArchiveDoctor(id)
      }

      if (user.role === ROLE.Specialist) {
        await chatAPI.moveToArchiveSpecialist(id)
      }
      showNotification({
        type: 'success',
        message: t('archived_successfully'),
      })
    } catch (e) {
    } finally {
      handleLoad()
    }
  }

  const handleToMessages = async (id: any) => {
    try {
      if (user.role === ROLE.Doctor) {
        await chatAPI.moveToMessagesDoctor(id)
      }

      if (user.role === ROLE.Specialist) {
        await chatAPI.moveToMessagesSpecialist(id)
      }
      showNotification({
        type: 'success',
        message: t('unarchived_successfully'),
      })
    } catch (e) {
    } finally {
      handleLoad()
    }
  }

  const canArchive = window.location.href?.indexOf('archive') === -1

  const columnConfig: any = {
    created_at: {
      accessorKey: 'created_at',
      id: 'created_at',
      header: t('created'),
      cell: (info: any) => <>{formatDateTime(info.row.original?.created_at)}</>,
    },
    updated_at: {
      accessorKey: 'updated_at',
      id: 'updated_at',
      header: t('updated'),
      cell: (info: any) => <>{formatDateTime(info.row.original?.updated_at)}</>,
    },
    sender: {
      accessorKey: 'sender',
      id: 'sender',
      header: t('sender'),
      cell: (info: any) => <>{info.row.original?.sender?.email}</>,
    },
    subject: {
      accessorKey: 'subject',
      id: 'subject',
      header: t('subject'),
      cell: (info: any) => (
        <>
          {info.row.original?.answer ? `${t('re')}: ` : ''}
          {info.row.original?.subject}
        </>
      ),
    },
    status: {
      accessorKey: 'status',
      id: 'status',
      header: t('status'),
      cell: (info: any) => (
        <Badge color={!isReadStatus(info.row.original) ? 'green' : 'gray'}>
          {!isReadStatus(info.row.original) ? t('new') : t('read')}
        </Badge>
      ),
    },
    open: {
      accessorKey: 'open',
      id: 'open',
      header: null,
      cell: (info: any) => (
        <Group>
          {/* <ButtonLink to={`/messages/${info.row.original?.id}`}>{t('open')}</ButtonLink> */}
          {canArchive ? (
            <Button
              variant={'outline'}
              onClick={(e) => {
                e.stopPropagation()
                handleArchive(info.row.original?.id)
              }}
            >
              {t('archive')}
            </Button>
          ) : (
            <Button
              variant={'outline'}
              onClick={(e) => {
                e.stopPropagation()
                handleToMessages(info.row.original?.id)
              }}
            >
              {t('unarchive')}
            </Button>
          )}
        </Group>
      ),
    },
  }

  useEffect(() => {
    const config = []

    config.push(columnConfig['status'])
    config.push(columnConfig['created_at'])
    // config.push(columnConfig['updated_at'])
    config.push(columnConfig['sender'])
    config.push(columnConfig['subject'])
    config.push(columnConfig['open'])

    setColumns(config)
  }, [])

  useEffect(() => {
    handleLoad()
  }, [page, chatStore.uuid])

  return (
    <PageContent>
      <PageHeader title={t('messages')} />

      <OverlayDataShower isLoading={loading} isFailed={false} error={null}>
        <Card>
          <TableView
            handleRowClick={(id) => navigate(`/messages/${id}`)}
            tableProps={{ highlightOnHover: true }}
            data={list}
            columns={columns}
            loading={loading}
          />

          <MetaPagination
            meta={meta}
            page={typeof page === 'number' ? page : 1}
            onChange={setPage}
            className={'mt-4'}
          />
        </Card>
      </OverlayDataShower>
    </PageContent>
  )
})
