import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ButtonProps } from '@mantine/core'
import { profileClientUpdatePA } from '@/features/profile/store'
import { useLang } from '@/hooks'
import { LangPicker } from '../LangPicker'

interface IProps {
  className?: string
  btnProps?: ButtonProps
}

export const AppLangPicker: React.FC<IProps> = ({ className, btnProps, ...props }) => {
  const { changeLang } = useLang()
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const currentLang = i18n.language

  const onChange = async (code: string) => {
    try {
      await changeLang(code)
      dispatch(profileClientUpdatePA.request({ lang: code }))
    } catch (e) {}
  }

  return <LangPicker value={currentLang} onChange={onChange} {...props} />
}
