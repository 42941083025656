import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Space, Title } from '@mantine/core'
import { Anchor, GeneralCard } from '@/components/Elements'
import { APP_NAME } from '@/const/core'
import { useFromPath } from '@/hooks'

export const TermsContainer = () => {
  const { t } = useTranslation()
  const from = useFromPath()

  return (
    <GeneralCard title={t('terms_and_conditions')} titleProps={{ order: 2 }}>
      <div className={'flex'}>
        <Title order={6}>
          {t('back_to')}{' '}
          <Anchor component={Link} to={from}>
            {APP_NAME}
          </Anchor>
        </Title>
      </div>

      <Space h={'lg'} />
    </GeneralCard>
  )
}
