import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { ColorSchemeToggle } from '@/components/Elements'
import { PageContent, PageHeader } from '@/components/Layouts'
import { ProfileContainer } from '@/features/profile/components/Profile'

export const Profile = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'profile'} />

      <PageContent>
        <PageHeader title={t('profile')} />
        <ProfileContainer />
      </PageContent>
    </>
  )
}
