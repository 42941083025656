import Pusher from 'pusher-js'
import React, { useEffect } from 'react'
import { RootNavigator } from '@/routes'
import { useUser } from './features/user/hooks'

function App() {
  return <RootNavigator />
}

export default App
