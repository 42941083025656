import React from 'react'
import { AuthLayout } from '@/components/Layouts'
import { ForgotPassword, Home, Login, Register, ResetPassword } from '@/features/auth/routes'
import { AuthGuard } from '@/routes/components'

//config
export const getAuthRoutes = (isLoggedIn: boolean) => {
  return [
    {
      path: '/*',
      element: <AuthGuard isLoggedIn={isLoggedIn} />,
      children: [
        {
          element: <AuthLayout />,
          children: [
            {
              index: !isLoggedIn,
              element: <Home />,
            },
            {
              path: `login`,
              element: <Login />,
            },
            {
              path: `register`,
              element: <Register />,
            },
            {
              path: 'forgot-password',
              element: <ForgotPassword />,
            },
            {
              path: 'reset-password/:token',
              element: <ResetPassword />,
            },
          ],
        },
      ],
    },
  ]
}
