import { createSlice } from '@reduxjs/toolkit'
import { TranslationModel } from '@/features/translation/types'

interface State {
  data: TranslationModel | null
  loading: boolean
  error: any
}

const initialState: State = {
  data: null,
  loading: false,
  error: null,
}

const translationEditDataSlice = createSlice({
  name: 'translationEditData',
  initialState,
  reducers: {
    getData(state, action) {
      state.loading = true
      state.error = null
    },
    getDataSuccess(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateData(state, action) {
      state.data = { ...state.data, ...action.payload }
    },
    cleanState() {
      return initialState
    },
  },
})

export const translationEditData = translationEditDataSlice.actions

export default translationEditDataSlice.reducer
