import { combineReducers } from 'redux'
import edit from './edit/reducers'
import entries from './entries/reducers'

const translationReducer = combineReducers({
  entries,
  edit,
})

export default translationReducer
