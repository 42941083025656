import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Pagination, PaginationProps } from '../Pagination'

interface IProps extends Omit<PaginationProps, 'total'> {
  page: number
  meta: {
    last_page: number
  }
  onChange: (page: number) => void
}

export const MetaPagination = ({
  page = 1,
  meta,
  onChange,
  position = 'right',
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const total = meta ? meta.last_page : 0

  const visible = useMemo(() => {
    return total > 1
  }, [total])

  return (
    <>
      {visible && (
        <Pagination {...props} position={position} page={page} total={total} onChange={onChange} />
      )}
    </>
  )
}
