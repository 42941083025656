import React from 'react'
import { ArchiveRoot, ChatsRoot, NewChatRoot } from '@/features/chat/routes'
import { Dashboard } from '@/features/dashboard/routes'
import { ProfileRoot, SettingsRoot } from '@/features/profile/routes'
import { ROLE } from '@/features/users/types'
import { GUARD } from '../../guards'

type PrivateRouteType = {
  index?: boolean
  path?: string
  element: React.ReactNode
  roles?: Array<string>
  guards: Array<GUARD>
}

const generalRoutes: PrivateRouteType[] = [
  {
    path: 'profile/*',
    element: <ProfileRoot />,
    roles: [ROLE.Doctor, ROLE.Specialist],
    guards: [],
  },
  {
    path: 'dashboard',
    element: <Dashboard />,
    roles: [ROLE.Doctor, ROLE.Specialist],
    guards: [],
  },
  {
    path: 'messages/*',
    element: <ChatsRoot />,
    roles: [ROLE.Doctor, ROLE.Specialist],
    guards: [],
  },
  {
    path: 'new-message/*',
    element: <NewChatRoot />,
    roles: [ROLE.Doctor],
    guards: [],
  },
  {
    path: 'archive/*',
    element: <ArchiveRoot />,
    roles: [ROLE.Doctor, ROLE.Specialist],
    guards: [],
  },
  {
    path: 'settings/*',
    element: <SettingsRoot />,
    roles: [ROLE.Doctor, ROLE.Specialist],
    guards: [],
  },
]

export const getGeneralRoutes = () => {
  return generalRoutes
}
