import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Settings } from '../settings/Profile'

export const SettingsRoot = () => {
  return (
    <Routes>
      <Route index element={<Settings />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
