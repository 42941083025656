import axios from 'axios'
import { UpdateTranslationDTO } from './dto'

class TranslationAPI {
  getTranslation = async (id: number, params: any = {}) => {
    return axios.get(`api/admin/translations/${id}`, {
      params,
    })
  }

  updateTranslation = async (id: number, data: UpdateTranslationDTO) => {
    return axios.put(`api/admin/translations/${id}`, data)
  }

  deleteTranslation = async (id: number) => {
    return axios.delete(`api/admin/translations/${id}`)
  }
}

export const translationAPI = new TranslationAPI()
