import React from 'react'
import { useMantineColorScheme } from '@mantine/core'
import { Logo } from './Logo'

interface IProps {
  type?: string

  [x: string]: any
}

export const LogoIcon = ({ type, ...props }: IProps) => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const dark = colorScheme === 'dark'

  switch (type) {
    default:
      return <Logo dark={dark} {...props} />
  }
}
