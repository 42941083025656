import { all, fork } from 'redux-saga/effects'
import { authSaga } from '@/features/auth/store/sagas'
import { panelSaga } from '@/features/panel/store/sagas'
import { profileSaga } from '@/features/profile/store/sagas'
import { staticSaga } from '@/features/static/store/sagas'
import { userSaga } from '@/features/user/store/sagas'

export default function* rootSaga() {
  yield all([fork(authSaga), fork(userSaga), fork(staticSaga), fork(profileSaga), fork(panelSaga)])
}
