import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent, PageHeader } from '@/components/Layouts'
import { NewChat as NewChatContainer } from '@/features/chat/components'

export const NewChat = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'messages_new'} />

      <PageContent>
        <PageHeader title={t('messages_new')} />
        <NewChatContainer />
      </PageContent>
    </>
  )
}
