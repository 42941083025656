import { createSlice } from '@reduxjs/toolkit'
import { User } from '@/features/user'
import { RootState } from '@/store'

interface UserDataState {
  data: User | null
}

const initialState: UserDataState = {
  data: null,
}

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    updateData(state, action) {
      state.data = {
        ...state.data,
        ...action.payload,
      }
    },
    cleanData() {
      return initialState
    },
  },
})

export const userData = userDataSlice.actions

export const selectUserData = (state: RootState) => state.user.data.data

export default userDataSlice.reducer
