import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { translationsAPI } from '@/features/translation/api'
import { langHelper } from '@/helpers'
import { EN } from '@/locales/en/translations'

// const resources = {
//   en: {
//     translation: EN,
//   },
// }

const DEFAULT_LANGUAGE_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE

const supportedLngs = langHelper.general.getLanguages().map(({ code }) => code)

const backendOptions = {
  loadPath: '{{lng}}|{{ns}}',
  request: async (options: any, url: string, payload: any, callback: any) => {
    try {
      const [lng] = url.split('|')
      const response = await translationsAPI.getTranslationsSingle({ lang: lng })
      const { data } = response

      callback(null, {
        data: data.data.translation,
        status: 200,
      })
    } catch (e) {
      callback(null, {
        status: 500,
      })
    }
  },
}

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage'],
    },
    // resources,
    fallbackLng: DEFAULT_LANGUAGE_CODE,
    supportedLngs,
    keySeparator: false, // we do not use keys in form messages.welcome

    backend: backendOptions,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    debug: false,

    // react-i18next options
    react: {
      useSuspense: true,
    },
  })

export default i18n
