import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { profilePasswordUpdatePA } from '@/features/profile/store'
import { useNotify } from '@/hooks'
import { PasswordForm } from './PasswordForm'

export const PasswordUpdate = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    await dispatch(profilePasswordUpdatePA.request(values))
    showNotification({
      type: 'success',
      message: t('password_changed_successfully'),
    })
  }

  return (
    <>
      <PasswordForm onSubmit={onSubmit} />
    </>
  )
}
