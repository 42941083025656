import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { HomeContent } from '@/features/auth/components/Home'

export const Home = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'home'} />

      <HomeContent />
    </>
  )
}
