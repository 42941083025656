import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { FormTypes } from '@/types'
import { authAPI } from '../../api'
import { RegisterDTO } from '../../api/dto'
import { authData } from '../data/slice'
import { AUTH_REGISTER_FAILED, AUTH_REGISTER_REQUEST, AUTH_REGISTER_SUCCESS } from './actionTypes'

export const authRegisterPA = createPromiseAction(
  AUTH_REGISTER_REQUEST,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_FAILED
)<RegisterDTO, unknown, unknown>()

function* register(action: PromiseAction<string, RegisterDTO, any>) {
  try {
    const response: AxiosResponse = yield call(authAPI.register, action.payload)
    const { data } = response

    yield put(authData.setData({ isLoggedIn: true, token: data?.data?.token }))

    yield put(authRegisterPA.success({}))
    resolvePromiseAction(action, {})
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* registerSaga() {
  yield takeEvery(authRegisterPA.request, register)
}
