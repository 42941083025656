import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { chatStore } from '@/features/chat/store/chatStore'
import { useUser } from '@/features/user/hooks'
import { FormatUtils } from '@/utils'
import { NavBadge } from '../NavBadge'

interface IProps {
  isActive: boolean
}

export const ChatUnreadBadge = observer(({ isActive }: IProps) => {
  const { user } = useUser()

  return <NavBadge isActive={isActive}>{chatStore.unread}</NavBadge>
})
