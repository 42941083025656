import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { Alert, LoadingScreen } from '@/components/Elements'
import { panelGeneral, panelLoader } from '@/features/panel/store'
import { useAppSelector } from '@/store'

export const PrivateLayout = () => {
  const dispatch = useDispatch()

  const { loading, error, prepared } = useAppSelector((state) => state.panel.loader)

  useEffect(() => {
    dispatch(panelLoader.loadData())

    return () => {
      dispatch(panelGeneral.resetState())
    }
  }, [])

  return loading ? (
    <LoadingScreen />
  ) : prepared ? (
    <>
      <Outlet />
    </>
  ) : error ? (
    <Alert type={'error'} mt={'lg'} mx={'lg'}>
      {error}
    </Alert>
  ) : null
}
