import { combineReducers } from 'redux'
import app from '@/features/app/store/reducers'
import auth from '@/features/auth/store/reducers'
import panel from '@/features/panel/store/reducers'
import staticReducer from '@/features/static/store/reducers'
import translation from '@/features/translation/store/reducers'
import user from '@/features/user/store/reducers'

const rootReducer = combineReducers({
  app,
  auth,
  user,
  static: staticReducer,
  panel,
  translation,
})

export default rootReducer
