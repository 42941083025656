import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { GeneralCard } from '@/components/Elements'
import { AvatarManager } from '@/features/profile/components/Profile/Avatar'
import { GeneralInfoEdit } from './GeneralInfoEdit'

export const GeneralInfo = () => {
  const { t } = useTranslation()
  return (
    <GeneralCard title={t('general_info')}>
      <Box mb={'md'}>
        <AvatarManager />
      </Box>

      <GeneralInfoEdit />
    </GeneralCard>
  )
}
