import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from '@/App'
import '@/assets/scss/index.scss'
import '@/i18n'
import '@/libs/axios'
import { ErrorBoundaryProvider, MantineProvider, QueryParam, StoreProvider } from '@/providers'
// stay for init service
import { LOCALE } from '@/services'
import reportWebVitals from './reportWebVitals'

const app = (
  <ErrorBoundaryProvider>
    <StoreProvider>
      <MantineProvider>
        <BrowserRouter>
          <QueryParam>
            <Suspense fallback="">
              <App />
            </Suspense>
          </QueryParam>
        </BrowserRouter>
      </MantineProvider>
    </StoreProvider>
  </ErrorBoundaryProvider>
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(app)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
