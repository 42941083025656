import _ from 'lodash'
import fr from '@/assets/images/langs/fr.webp'
import ge from '@/assets/images/langs/ge.webp'
import it from '@/assets/images/langs/it.webp'
import uk from '@/assets/images/langs/uk.jpg'

export const getLanguages = () => {
  return [
    {
      code: 'en',
      title: 'English',
      flag: uk,
    },
    {
      code: 'fr',
      title: 'French',
      flag: fr,
    },
    {
      code: 'de',
      title: 'German',
      flag: ge,
    },
    {
      code: 'it',
      title: 'Italian',
      flag: it,
    },
  ]
}

export const getLang = (code: string) => {
  const options = getLanguages()
  return _.find(options, { code })
}
