import React from 'react'
import { ColorScheme, ColorSchemeProvider, MantineProvider as Provider } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { NotificationsProvider } from '@mantine/notifications'
import { Fonts, GlobalStyles, globalTheme } from '@/assets/theme'

type Props = {
  children: React.ReactNode
}

export const MantineProvider: React.FC<Props> = ({ children }) => {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  })

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <Provider theme={{ ...globalTheme, colorScheme }} withGlobalStyles withNormalizeCSS>
        <Fonts />
        <GlobalStyles />
        <NotificationsProvider position="top-right">{children}</NotificationsProvider>
      </Provider>
    </ColorSchemeProvider>
  )
}
