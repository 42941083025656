import * as React from 'react'
import { Navbar as MantineNavbar, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { NavbarContent } from './NavbarContent'
import { NavbarDrawer } from './NavbarDrawer'

interface IProps {
  drawerOpened: boolean
  onDrawerClose: () => void
}

export const Navbar = ({ drawerOpened, onDrawerClose }: IProps) => {
  const theme = useMantineTheme()
  const isSmallerThenMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`)

  return (
    <>
      <MantineNavbar
        width={{ base: isSmallerThenMd ? 0 : 300 }}
        hiddenBreakpoint={'md'}
        hidden={true}
        withBorder={theme.colorScheme === 'dark'}
        style={{
          boxShadow: theme.shadows.xs,
        }}
      >
        <NavbarContent />
      </MantineNavbar>

      <NavbarDrawer opened={drawerOpened} onClose={onDrawerClose} />
    </>
  )
}
