import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Profile } from '../profile'

export const ProfileRoot = () => {
  return (
    <Routes>
      <Route index element={<Profile />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
