import * as Yup from 'yup'
import { PASSWORD_MIN_LENGTH } from '@/const/form'

export const validationSchema = Yup.object().shape({
  current_password: Yup.string()
    .required('field.error.required')
    .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
  password: Yup.string()
    .required('field.error.required')
    .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
  password_confirmation: Yup.string()
    .required('field.error.required')
    .oneOf([Yup.ref('password'), null], 'field.error.password.no_match')
    .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
})
