import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { Space, Title } from '@mantine/core'
import { Alert, Anchor } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'
import { useLang } from '@/hooks'
import { useAppDispatch } from '@/store'
import { authRegisterPA } from '../../../store'
import { FormValues, RegisterForm } from './RegisterForm'

export const RegisterContainer = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { lang } = useLang()

  const [success, setSuccess] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const handleSubmit = async (values: FormValues) => {
    const token = searchParams.get('token')
    if (!token) return

    setSuccess(false)
    const params = {
      ...values,
      phone: values.phone.replace(/[^0-9]/g, ''),
      invite_token: token,
      lang,
    }

    try {
      await dispatch(authRegisterPA.request(params))
    } catch (e) {}
    setSuccess(true)
  }

  return (
    <AuthCard title={t('sign_up')}>
      <div className={'flex'}>
        <Title order={6}>
          {t('already_have_account')}?{' '}
          <Anchor component={Link} to={'/login'}>
            {t('login')}
          </Anchor>
        </Title>
      </div>

      <Space h={'md'} />

      <RegisterForm onSubmit={handleSubmit} />
    </AuthCard>
  )
}
