import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { Chats as ChatsComponent } from '../../components/Chats'

export const Chats = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'chats'} />

      <PageContent>
        <ChatsComponent />
      </PageContent>
    </>
  )
}
