import React from 'react'
import { useTranslation } from 'react-i18next'
import { GeneralCard } from '@/components/Elements'
import { PasswordUpdate } from './PasswordUpdate'

export const Password = () => {
  const { t } = useTranslation()
  return (
    <GeneralCard title={t('change_password')}>
      <PasswordUpdate />
    </GeneralCard>
  )
}
