import * as React from 'react'
import { Link } from 'react-router-dom'
import { Container, Header as MantineHeader } from '@mantine/core'
import { LogoIcon } from '@/components/Elements'
import { Anchor, ColorSchemeToggle } from '@/components/Elements'
import { HEADER_HEIGHT } from '@/const/layout'
import useStyles from './Header.styles'

interface IProps {
  lang?: boolean
  colorScheme?: boolean
}

export const Header = ({ lang = false, colorScheme = false }: IProps) => {
  const { classes } = useStyles()

  return (
    <MantineHeader
      style={{ boxShadow: '-8px 12px 18px 0 #dadee8' }}
      height={HEADER_HEIGHT}
      fixed
      zIndex={1}
    >
      <Container>
        <div className={classes.inner}>
          <Anchor component={Link} to={'/'}>
            <LogoIcon size={'sm'} />
          </Anchor>
        </div>
      </Container>
    </MantineHeader>
  )
}
