import { MantineThemeColorsOverride } from '@mantine/core'

export const overrideColors: MantineThemeColorsOverride = {
  secondary: [
    '#F8FCFC',
    '#EDF8F6',
    '#DBF0EE',
    '#C9E9E5',
    '#B3E0DA',
    '#9FD7D0',
    '#76C6BD',
    '#49B0A4',
    '#3D948A',
    '#2D6C65',
  ],
  primary: [
    '#F8FCFC',
    '#EDF8F6',
    '#DBF0EE',
    '#C9E9E5',
    '#B3E0DA',
    '#9FD7D0',
    '#76C6BD',
    '#49B0A4',
    '#3D948A',
    '#2D6C65',
  ],
  blue: [
    'rgba(1, 98, 232, 0.1)',
    'rgba(1, 98, 232, 0.2)',
    'rgba(1, 98, 232, 0.4)',
    'rgba(1, 98, 232, 0.6)',
    'rgba(1, 98, 232, 0.8)',
    'rgba(1, 98, 232, 1)',
    '#034fb7',
    '#033d8d',
    '#023172',
    '#022657',
  ],
  gray: [
    '#ecf0fa',
    '#F1F3F5',
    '#E9ECEF',
    '#DEE2E6',
    '#CED4DA',
    '#ADB5BD',
    '#868E96',
    '#495057',
    '#343A40',
    '#212529',
  ],
}
