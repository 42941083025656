import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationControl } from '@/components/Controls'
import { GeneralCard } from '@/components/Elements'
import { Button } from '@/components/Elements'
import { useProfileDelete } from '@/features/profile/hooks'

export const ProfileDelete = () => {
  const { t } = useTranslation()

  const { onRemove, loading } = useProfileDelete()

  return (
    <GeneralCard title={t('deactivation')}>
      <ConfirmationControl
        onConfirm={onRemove}
        render={({ onOpen }) => (
          <Button loading={loading} color={'gray'} variant={'outline'} onClick={onOpen}>
            {t('delete_account')}
          </Button>
        )}
      />
    </GeneralCard>
  )
}
