import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { SelectController, TextInputController } from '@/components/FormElements'
import { authAPI } from '@/features/auth/api'
import { useUser } from '@/features/user/hooks'
import { NormalizeUtils } from '@/utils'

export type GeneralFormSectionValues = {
  first_name: string
  last_name: string
  email: string
  phone: string
  title: string
  doctor_association: string
  city: string
  address: string
  specialist: string
  // lang: string
}

export const GeneralFormSection = () => {
  const { t } = useTranslation()
  const { isDoctor, isSpecialist } = useUser()

  const [roles, setRoles] = useState([])

  const loadRoles = async () => {
    try {
      const { data } = await authAPI.specialistsRoles()

      const dataRoles = data?.data?.map((el: any) => ({ value: `${el?.id}`, label: el?.name }))

      setRoles(dataRoles)
    } catch (e) {}
  }

  useEffect(() => {
    loadRoles()
  }, [])

  const { control } = useFormContext<GeneralFormSectionValues>()

  return (
    <>
      <Grid>
        <Grid.Col md={4}>
          <TextInputController
            control={control}
            name={'title'}
            id={'title'}
            label={t('title')}
            placeholder={t('title')}
            mb={'md'}
          />
        </Grid.Col>
        {isDoctor() && (
          <Grid.Col md={8}>
            <TextInputController
              control={control}
              name={'doctor_association'}
              id={'doctor_association'}
              label={t('doctor_association')}
              placeholder={t('doctor_association')}
              mb={'md'}
            />
          </Grid.Col>
        )}

        {isSpecialist() && (
          <Grid.Col md={8}>
            <SelectController
              control={control}
              name={'specialist'}
              id={'specialist'}
              label={t('specialist')}
              placeholder={t('specialist')}
              data={roles?.map((el: any) => ({ ...el, label: t(`${el.label}`) })) || []}
              mb={'md'}
            />
          </Grid.Col>
        )}
      </Grid>
      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'city'}
            id={'city'}
            label={t('city')}
            placeholder={t('city')}
            mb={'md'}
          />
        </Grid.Col>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'address'}
            id={'address'}
            label={t('address')}
            placeholder={t('address')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'first_name'}
            id={'first_name'}
            label={t('first_name')}
            placeholder={t('first_name')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'last_name'}
            id={'last_name'}
            label={t('last_name')}
            placeholder={t('last_name')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'email'}
            id={'email'}
            label={t('email')}
            placeholder={t('email')}
            mb={'md'}
            readOnly
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'phone'}
            id={'phone'}
            label={t('phone')}
            placeholder={t('phone')}
            mb={'md'}
            normalize={NormalizeUtils.phone}
          />
        </Grid.Col>
      </Grid>

      {/*<Grid>*/}
      {/*  <Grid.Col md={6}>*/}
      {/*    <LangPickerController control={control} name={'lang'} label={t('language')} mb={'md'} />*/}
      {/*  </Grid.Col>*/}
      {/*</Grid>*/}
    </>
  )
}
