import Pusher from 'pusher-js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRoutes } from 'react-router-dom'
import { useAuth } from '@/features/auth/hooks'
import { chatStore } from '@/features/chat/store/chatStore'
import { useUser } from '@/features/user/hooks'
import { useNotify } from '@/hooks'
import { getRootRoutes } from '@/routes'

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || '', {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER || '',
  authEndpoint: process.env.REACT_APP_API_URL + 'api/broadcasting/auth',
})

export const RootNavigator = () => {
  const { isLoggedIn } = useAuth()
  const { t } = useTranslation()
  const { showNotification } = useNotify()
  const { user } = useUser()

  useEffect(() => {
    if (user?.id) {
      const channel = pusher.subscribe(`conversations.user.${user.id}`)
      channel.bind('App\\Events\\MessageCreatedEvent', (data: any) => {
        showNotification({
          type: 'success',
          message: t('new_message'),
        })

        chatStore.handleStats(user?.id)
        chatStore.handleNewMessage()
      })

      chatStore.handleStats(user?.id)
    }
  }, [user])

  return useRoutes(getRootRoutes(isLoggedIn, user))
}
