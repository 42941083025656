import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const dark = theme.colorScheme === 'dark'

  return {
    main: {
      backgroundColor: dark ? theme.colors.dark[7] : theme.colors.gray[0],
    },
  }
})
