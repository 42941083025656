import queryString from 'query-string'
import React from 'react'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

type Props = {
  children: React.ReactNode
}

export const QueryParam: React.FC<Props> = ({ children }) => {
  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: queryString.parse,
        objectToSearchString: queryString.stringify,
      }}
    >
      {children}
    </QueryParamProvider>
  )
}
