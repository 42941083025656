import * as React from 'react'
import { Link } from 'react-router-dom'
import { Group } from '@mantine/core'
import { Anchor, LogoIcon } from '@/components/Elements'
import useStyles from './Header.styles'

interface IProps {
  onNavLinkClick?: () => void
}

export const Header = ({ onNavLinkClick }: IProps) => {
  const { classes } = useStyles()

  const onLinkClick = () => {
    onNavLinkClick && onNavLinkClick()
  }

  return (
    <div className={classes.root}>
      <Group>
        <Anchor component={Link} to={'/'} onClick={onLinkClick}>
          <LogoIcon size={'sm'} />
        </Anchor>
      </Group>
    </div>
  )
}
