import * as Yup from 'yup'
import { ValidationsUtils } from '@/utils'

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().nullable().required('field.error.required'),
  last_name: Yup.string().nullable().required('field.error.required'),
  phone: Yup.string()
    .test('phone', 'field.error.invalid', ValidationsUtils.phoneFormat)
    .required('field.error.required'),
})
