import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { NewChat } from '../newChat'

export const NewChatRoot = () => {
  return (
    <Routes>
      <Route index element={<NewChat />} />
      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
