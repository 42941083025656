import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { PRIVAT_INITIAL_ROUTE } from '@/routes/const'

interface IProps {
  [x: string]: any
}

export const PanelSwitch: React.FC<IProps> = ({ children, roles, ...props }) => {
  const location = useLocation()

  return <Navigate to={PRIVAT_INITIAL_ROUTE} state={{ from: location }} />
}
