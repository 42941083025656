import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Stack } from '@mantine/core'
import { Avatar, Button } from '@/components/Elements'
import { FILE_MAX_SIZE } from '@/const/form'
import { chatAPI } from '@/features/chat/api'
import { useNotify } from '@/hooks'
import { FileUtils, ValidationsUtils } from '@/utils'

export const UploadControl = ({ handleSelected, selectedList }: any) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const inputRef = useRef<HTMLInputElement>(null)

  const selectFile = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  useEffect(() => {
    handleSelected(selectedList)
  }, [selectedList])

  const onUpload = async (file: any) => {
    try {
      const { data } = await chatAPI.uploadFile(file)

      if (data) {
        handleSelected([...selectedList, data])
      }

      showNotification({
        type: 'success',
        message: t('uploaded_successfully'),
      })
    } catch (e) {}
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    const file = event.target.files[0]

    const isValid = ValidationsUtils.fileMaxSize(file, FILE_MAX_SIZE)

    if (!isValid) {
      const message = t('file.max_size.error', {
        text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
      })
      showNotification({ type: 'error', message: message })

      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)

    onUpload(file)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <>
      <Group>
        <input type="file" onChange={onInputChange} ref={inputRef} className={'hidden'} />

        <Stack spacing="xs">
          <Button
            onClick={selectFile}
            variant={'default'}
            size={'sm'}
            disabled={false}
            loading={false}
          >
            {t('upload')}
          </Button>
        </Stack>
      </Group>
    </>
  )
}
