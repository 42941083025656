import React, { useMemo } from 'react'
import { Button, ButtonProps, Menu, Text } from '@mantine/core'
import { langHelper } from '@/helpers'

interface IProps {
  value?: string
  onChange?: (value: string) => void
  className?: string
  btnProps?: ButtonProps
}

const languages = langHelper.general.getLanguages()

export const LangPicker: React.FC<IProps> = ({
  value,
  onChange,
  className,
  btnProps,
  ...props
}) => {
  const langData = useMemo(() => {
    return value ? langHelper.general.getLang(value) : null
  }, [value])

  const onLangChange = (code: string) => {
    if (code !== value) {
      onChange && onChange(code)
    }
  }

  return (
    <Menu width={200}>
      <Menu.Target>
        <Button
          size={'sm'}
          variant="default"
          leftIcon={<img src={langData?.flag} alt={langData?.code} style={{ height: '16px' }} />}
          {...btnProps}
        >
          <Text transform="uppercase">{langData?.code}</Text>
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        {languages.map((item) => (
          <Menu.Item
            key={item.code}
            icon={<img src={item.flag} alt={item.code} style={{ height: '16px' }} />}
            onClick={() => onLangChange(item.code)}
          >
            {item.title}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}
