import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    display: 'flex',
    minWidth: '30px',
    height: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: 700,
    paddingLeft: '5px',
    paddingRight: '5px',
    borderRadius: '1000px',
    color: theme.white,
    backgroundColor: theme.colors.blue,
    whiteSpace: 'nowrap',
  },

  active: {
    color: theme.colors.blue,
    backgroundColor: theme.white,
  },
}))
