import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Title } from '@mantine/core'
import { ButtonLink } from '@/components/Elements'
import useStyles from './HomeContent.styles'

export const HomeContent = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <>
      <Title>{t('home')}</Title>

      <Box mt={'xl'}>
        <ButtonLink to={'/login'}>{t('login')}</ButtonLink>
      </Box>
    </>
  )
}
