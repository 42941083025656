import React from 'react'
import { useTranslation } from 'react-i18next'
import { profileAvatarRemovePA, profileAvatarUploadPA } from '@/features/profile/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

export const useAvatarManager = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading: uploading, onAsyncDispatch: onUploadDispatch } = useAsyncDispatch()

  const onUpload = async (file: File) => {
    try {
      const data = {
        avatar: file,
      }

      await onUploadDispatch(profileAvatarUploadPA.request, data)

      showNotification({
        type: 'success',
        message: t('saved_successfully'),
      })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  const { loading: removing, onAsyncDispatch: onRemoveDispatch } = useAsyncDispatch()

  const onRemove = async () => {
    try {
      await onRemoveDispatch(profileAvatarRemovePA.request)
      showNotification({
        type: 'success',
        message: t('deleted_successfully'),
      })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    uploading,
    onUpload,
    removing,
    onRemove,
  }
}
