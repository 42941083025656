import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent, PageHeader } from '@/components/Layouts'
import { SettingsContainer } from '@/features/profile/components/Profile/ProfileContainer/Settings/Settings'
import { SupportContainer } from '@/features/profile/components/Profile/ProfileContainer/Settings/SupportContainer'

export const Settings = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'settings'} />

      <PageContent>
        <PageHeader title={t('settings')} />
        <SettingsContainer />
        <SupportContainer />
      </PageContent>
    </>
  )
}
