import * as Yup from 'yup'

export const recipientIdSchema = Yup.string().required('field.error.required')
export const subjectSchema = Yup.string().required('field.error.required')
export const contentSchema = Yup.string().required('field.error.required')
export const avsCodeSchema = Yup.string().required('field.error.required')
export const insuranceSchema = Yup.string().required('field.error.required')
export const insuranceNumberSchema = Yup.string().required('field.error.required')

export const validationSchema = Yup.object().shape({
  recipient_id: recipientIdSchema,
  subject: subjectSchema,
  content: contentSchema,
  avs_code: avsCodeSchema,
  insurance: insuranceSchema,
  insurance_number: insuranceNumberSchema,
})
