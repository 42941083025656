import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Space, Title } from '@mantine/core'
import { Anchor } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'
import { useAppDispatch } from '@/store'
import { authLoginPA, authVerifyCodePA, authVerifyEmailPA } from '../../../store'
import { FormValues, LoginForm } from './LoginForm'

export const LoginContainer = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleSubmit = async (values: FormValues) => {
    await dispatch(authLoginPA.request(values))
  }

  const onVerifyEmail = async (email: string) => {
    await dispatch(authVerifyEmailPA.request({ email }))
  }

  const onVerifyCode = async (email: string) => {
    await dispatch(authVerifyCodePA.request({ email }))
  }

  return (
    <AuthCard title={t('login')}>
      <LoginForm
        onSubmit={handleSubmit}
        onVerifyEmail={onVerifyEmail}
        onVerifyCode={onVerifyCode}
      />
    </AuthCard>
  )
}
