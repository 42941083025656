import React from 'react'
import { usePopup } from '@/hooks'

interface IProps {
  confirm: (data: any) => void
}

export const useConfirmPopup = ({ confirm }: IProps) => {
  const { data, visible, open, close, resetData } = usePopup()

  const onConfirm = () => {
    confirm && confirm(data)

    close()
  }

  return { data, visible, open, close, resetData, onConfirm }
}
