import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { Alert, Button, GeneralCard } from '@/components/Elements'
import { TextAreaController } from '@/components/FormElements'
import { profileAPI } from '@/features/profile/api'
import { useFormSubmit } from '@/hooks'
import { useNotify } from '@/hooks'

export const SupportContainer = () => {
  const { t } = useTranslation()
  const { showNotification } = useNotify()

  const onSubmitForm = async (values: any) => {
    try {
      await profileAPI.createTicket(values)

      showNotification({
        type: 'success',
        message: t('send_successfully'),
      })
    } catch (e) {
    } finally {
      setValue('description', '')
    }
  }

  const defaultValues = {
    description: '',
  }

  const methods = useForm({
    defaultValues,
  })

  const {
    handleSubmit,
    setError,
    control,
    setValue,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: onSubmitForm,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (error) {}
  }

  return (
    <GeneralCard title={t('support')}>
      <Box mt={'md'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {submitError && (
            <Alert type={'error'} mb={'sm'}>
              {submitError?.message || t('error')}
            </Alert>
          )}

          <TextAreaController
            control={control}
            name={'description'}
            id={'description'}
            label={t('description')}
            placeholder={t('description')}
            mb={'md'}
          />

          <Button mt={'lg'} type={'submit'} loading={isSubmitting}>
            {t('create')}
          </Button>
        </form>
      </Box>
    </GeneralCard>
  )
}
