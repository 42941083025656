import { createStyles } from '@mantine/core'
import { HEADER_HEIGHT } from '@/const/layout'

export default createStyles((theme) => ({
  root: {},

  wrapper: {
    // height: `calc(100vh)`, // need fix: need 100vh - header height + paddings (but layout can be without header)
    // minHeight: '100vh',
  },

  content: {
    height: '100%',
    padding: '16px 0',
    paddingTop: `calc(${HEADER_HEIGHT}px + 16px)`,
    marginTop: HEADER_HEIGHT,
  },
}))
