import React from 'react'
import { User } from '@/features/user'
import { selectUserData } from '@/features/user/store'
import { useUsersModel } from '@/features/users/hooks'
import { useAppSelector } from '@/store'

export const useUser = () => {
  const user = useAppSelector(selectUserData) as User

  const usersModel = useUsersModel()

  const isSpecialist = () => {
    return usersModel.isSpecialist(user)
  }

  const isDoctor = () => {
    return usersModel.isDoctor(user)
  }

  const isClient = () => {
    return usersModel.isClient(user)
  }

  const getName = () => {
    return usersModel.getName(user)
  }

  return {
    user,
    isDoctor,
    isSpecialist,
    isClient,
    getName,
  }
}
