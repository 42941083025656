import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Space, Title } from '@mantine/core'
import { Alert, Anchor } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'
import { authForgotPasswordPA } from '../../../store'
import { ForgotPasswordForm, FormValues } from './ForgotPasswordForm'

export const ForgotPasswordContainer = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [success, setSuccess] = useState<string | null>(null)

  const handleSubmit = async (values: FormValues) => {
    setSuccess(null)

    const { message } = await dispatch(authForgotPasswordPA.request(values))

    setSuccess(message || t('success'))
  }

  return (
    <AuthCard title={t('forgot_password')}>
      {success && (
        <Alert type={'success'} mb={'sm'}>
          {success}
        </Alert>
      )}

      <ForgotPasswordForm onSubmit={handleSubmit} />

      <Space h={'lg'} />

      <div className={'mt-4 flex'}>
        <Title order={6}>
          {t('remember_it')}?{' '}
          <Anchor component={Link} to={'/login'}>
            {t('login')}
          </Anchor>
        </Title>
      </div>
    </AuthCard>
  )
}
