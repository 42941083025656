import React from 'react'
import { Title } from '@mantine/core'
import { Card } from '../Card'
import useStyles from './GeneralCard.styles'

interface IProps {
  children: React.ReactNode
  title?: React.ReactNode
  className?: string
  titleProps?: any
}

export const GeneralCard: React.FC<IProps> = ({
  children,
  title,
  className,
  titleProps,
  ...props
}) => {
  const { classes, cx } = useStyles()

  const renderTitle = () => {
    if (typeof title === 'string') {
      return (
        <Title mb={'md'} order={4} {...titleProps}>
          {title}
        </Title>
      )
    }

    return title
  }

  return (
    <Card
      className={cx(classes.card, {
        [`${className}`]: className,
      })}
      shadow={'xs'}
      py={32}
      px={60}
      {...props}
    >
      {title && renderTitle()}

      {children}
    </Card>
  )
}
