import axios from 'axios'
import { ForgotPasswordDTO, LoginDTO, RegisterDTO, ResetPasswordDTO, VerifyEmailDTO } from './dto'

class AuthAPI {
  login = async (data: LoginDTO) => {
    return axios.post('api/auth/login', data)
  }

  verifyCredentials = async (data: LoginDTO) => {
    return axios.post('api/auth/simple-login', data)
  }

  register = async (data: RegisterDTO) => {
    return axios.post('api/auth/register', data)
  }
  logOut = async () => {
    return axios.post('api/auth/logout')
  }
  verifyEmail = async (data: VerifyEmailDTO) => {
    return axios.post('api/auth/email/verification-notification', data)
  }
  verifyCode = async (data: VerifyEmailDTO) => {
    const url = `api/auth/2fa/code?email=${data.email?.replace(/\+/g, '%2B')}`
    const encodedUrl = encodeURI(url)

    return axios.get(url)
  }
  forgotPassword = async (data: ForgotPasswordDTO) => {
    return axios.post('api/auth/forgot-password', data)
  }
  resetPassword = async (data: ResetPasswordDTO) => {
    return axios.post('api/auth/reset-password', data)
  }
  specialistsRoles = async () => {
    return axios.get('api/specialists')
  }
  authInfo = async (token: any) => {
    return axios.get(`api/registration-info/${token}`)
  }
}

export const authAPI = new AuthAPI()
