import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { staticAPI } from '@/features/static/api'
import i18n from '@/i18n'
import { staticSelects } from './slice'

export function* getData() {
  try {
    const response: AxiosResponse = yield call(staticAPI.getSelects)

    const { data } = response

    yield put(staticSelects.getDataSuccess(data))

    return data?.data
  } catch (error: any) {
    const message = error?.response?.data?.message || i18n.t('error')
    yield put(staticSelects.getDataError(message))

    throw error
  }
}

export function* watchGetData() {
  yield takeEvery(staticSelects.getData, getData)
}

export function* selectsSaga() {
  yield all([fork(watchGetData)])
}
