import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { Badge, Box, Card, Grid, Group, Text, Tooltip } from '@mantine/core'
import {
  Alert,
  Button,
  MetaPagination,
  Modal,
  OverlayDataShower,
  TableView,
} from '@/components/Elements'
import { PageContent, PageHeader } from '@/components/Layouts'
import { chatAPI } from '@/features/chat/api'
import { useUser } from '@/features/user/hooks'
import { ROLE } from '@/features/users/types'
import { langHelper } from '@/helpers'
import { useNotify } from '@/hooks'
import { formatDateTime } from '@/utils/date'
import { chatStore } from '../../store/chatStore'
import { Answer } from './Answer'

export const Chat = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { id } = useParams()
  const { t } = useTranslation()
  const { showNotification } = useNotify()
  const { user } = useUser()
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<null | 'string'>(null)
  const [loading, setLoading] = useState(false)

  const [isOpenDownload, setIsOpenDownload] = useState(false)

  const navigate = useNavigate()

  const componentRef = useRef<any>()
  const doctorMessageRef = useRef<any>()

  const onPrint = useReactToPrint({
    content: () => componentRef?.current,
  })

  const onPrintDoctor = useReactToPrint({
    content: () => doctorMessageRef?.current,
  })

  const handlePrint = () => {
    if (user.role === ROLE.Doctor) {
      onPrintDoctor()
    } else {
      onPrint()
    }

    chatAPI.printed(id)
  }

  const onLoad = async () => {
    try {
      setLoading(true)

      const { data } = await chatAPI.getConversation(id)

      data?.data && setData(data?.data)
    } catch (error: any) {
      if (error?.response?.data?.error) setError(error?.response?.data?.error)
    } finally {
      setLoading(false)
    }
  }

  function download(url: any, filename: any) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
        showNotification({
          type: 'success',
          message: t('downloaded'),
        })
      })
      .catch(() => {})
  }

  const handleGetFile = async (data: any) => {
    try {
      const res = await chatAPI.getFile(data?.id)

      if (res?.data?.path) {
        download(res?.data?.path, data?.name)
      }
    } catch (e) {}
  }

  const handleRead = async () => {
    if (user.role === ROLE.Doctor) {
      await chatAPI.doctorRead(id)
    }

    if (user.role === ROLE.Specialist) {
      await chatAPI.specialistRead(id)
    }

    await chatStore.handleStats(user.id)
  }

  const printDocument = async () => {
    const input = document.getElementById('dpfDoc')
    if (!input) return

    html2canvas(input).then((canvas: any) => {
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF()

      if (!pdf && !imgData) return

      pdf.addImage(imgData, 'JPEG', 0, 0, 0, 0)
      pdf.save(`${data?.recipient?.email}_${formatDateTime(data?.question?.created_at)}.pdf`)
    })
  }

  const downloadTemplate = async () => {
    try {
      const res: any = await chatAPI.getEmailTemplate(id)
      const blob = new Blob([res?.data], { type: 'application/pdf' })
      saveAs(blob, `email template ${formatDateTime(new Date())}.pdf`)
    } catch (e) {}
  }

  useEffect(() => {
    const useDownload = searchParams.get('download')
    if (useDownload && data) {
      setIsOpenDownload(true)
    }
  }, [data])

  useEffect(() => {
    handleRead()

    onLoad()
  }, [])

  if (error) {
    return (
      <PageContent>
        <PageHeader title={t('chat')} />
        <Alert variant="light" color="red" title={<Text size="lg">{t('error')}</Text>}>
          <Text size="lg">{error}</Text>
        </Alert>
      </PageContent>
    )
  }

  return (
    <PageContent>
      <Modal opened={isOpenDownload} onClose={() => setIsOpenDownload(false)} title={t('download')}>
        <Group position={'center'}>
          <Button onClick={downloadTemplate}>{t('print')}</Button>
        </Group>
      </Modal>
      <PageHeader title={t('chat')} />
      <div ref={componentRef}>
        <OverlayDataShower isLoading={loading} isFailed={false} error={null}>
          <Grid>
            <Grid.Col md={2}></Grid.Col>
            <Grid.Col md={10}>
              <div ref={doctorMessageRef} id="dpfDoc">
                <Alert
                  variant="filled"
                  style={{
                    background:
                      'linear-gradient(to right, rgba(1, 98, 232, 1), rgba(1, 98, 232, 0.6) 100%',
                  }}
                  title={<Text size="lg">{t('question')}</Text>}
                >
                  <Text size={'md'}>{data?.question?.content}</Text>
                  <Text mt={'md'} size={'xs'}>
                    {formatDateTime(data?.question?.created_at)}
                  </Text>
                  <Text size={'xs'}>
                    {t('from')}: {data?.sender?.email} {data?.sender?.first_name || ''}{' '}
                    {data?.sender?.last_name || ''}
                  </Text>
                  <Text size={'xs'}>
                    {t('to')}: {data?.recipient?.email} {data?.recipient?.first_name || ''}{' '}
                    {data?.recipient?.last_name || ''}
                  </Text>
                  <Text size={'xs'}>
                    {t('avs_code')}: {data?.patient?.avs_code || '-'}
                  </Text>
                  <Text size={'xs'}>
                    {t('insurance')}: {data?.patient?.insurance || '-'}
                  </Text>
                  <Text size={'xs'}>
                    {t('insurance_number')}: {data?.patient?.insurance_number || '-'}
                  </Text>
                  <Group mt={'sm'}>
                    {data?.question?.attachments?.length ? (
                      data?.question?.attachments.map((item: any) => (
                        <Tooltip label={item?.name}>
                          <Card onClick={() => handleGetFile(item)} maw={200}>
                            <Text truncate>{item?.name}</Text>
                          </Card>
                        </Tooltip>
                      ))
                    ) : (
                      <Text size={'md'}>{t('no_attachments')}</Text>
                    )}
                  </Group>
                </Alert>
              </div>
              <Group mt={'xl'} position={'right'}>
                <Button onClick={downloadTemplate}>{t('print')}</Button>
              </Group>
            </Grid.Col>
          </Grid>
          {data?.answer?.id ? (
            <Grid>
              <Grid.Col md={10}>
                <Alert
                  variant="filled"
                  style={{
                    background: 'linear-gradient(to left,#48d6a8 0,#029666 100%)',
                  }}
                  title={<Text size="lg">{t('answer')}</Text>}
                >
                  <Text size={'md'}>{data?.answer?.content}</Text>
                  <Text mt={'md'} size={'xs'}>
                    {formatDateTime(data?.answer?.created_at)}
                  </Text>
                  <Text size={'xs'}>
                    {t('from')}: {data?.recipient?.email} {data?.recipient?.first_name || ''}{' '}
                    {data?.recipient?.last_name || ''}
                  </Text>
                  <Text size={'xs'}>
                    {t('to')}: {data?.sender?.email} {data?.sender?.first_name || ''}{' '}
                    {data?.sender?.last_name || ''}
                  </Text>
                  <Group mt={'sm'}>
                    {data?.answer?.attachments?.length ? (
                      data?.answer?.attachments.map((item: any) => (
                        <Tooltip label={item?.name}>
                          <Card onClick={() => handleGetFile(item)} maw={200}>
                            <Text truncate>{item?.name}</Text>
                          </Card>
                        </Tooltip>
                      ))
                    ) : (
                      <Text size={'md'}>{t('no_attachments')}</Text>
                    )}
                  </Group>
                </Alert>
              </Grid.Col>
              <Grid.Col md={2}></Grid.Col>
            </Grid>
          ) : (
            <>
              {user.role === ROLE.Doctor ? (
                <Group mt={'lg'} position={'center'}>
                  <Text size={'lg'}>{t('no_answer')}</Text>
                </Group>
              ) : (
                <Grid>
                  <Grid.Col md={10}>
                    <Alert
                      variant="light"
                      color="indigo"
                      title={<Text size="lg">{t('answer')}</Text>}
                    >
                      <Answer />
                    </Alert>
                  </Grid.Col>
                  <Grid.Col md={2}></Grid.Col>
                </Grid>
              )}
            </>
          )}
        </OverlayDataShower>
      </div>
    </PageContent>
  )
}
