import React from 'react'
import { AnchorProps, Anchor as MantineAnchor } from '@mantine/core'

interface IProps extends AnchorProps {
  children: React.ReactNode

  [x: string]: any
}

export const Anchor: React.FC<IProps> = ({ children, ...props }) => {
  return <MantineAnchor {...props}>{children}</MantineAnchor>
}
