import { ModelTypes } from '@/types'

export enum ROLE {
  Client = 'client',
  Doctor = 'doctor',
  Specialist = 'specialist',
}

export type BaseUserType = {
  id: number
  avatar: ModelTypes.AvatarType | null
  email: string
  first_name: string | null
  last_name: string | null
  specialist?: any
  role: ROLE
  phone: string | null
  is_notify: boolean
}

// eslint-disable-next-line
export interface UserModel extends BaseUserType {}
