import React from 'react'
import { useTranslation } from 'react-i18next'
import { authLogoutPA } from '@/features/auth/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

export const useLogout = () => {
  const { t } = useTranslation()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const { showNotification } = useNotify()

  const logout = async () => {
    try {
      await onAsyncDispatch(authLogoutPA.request)
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    logout,
    loading,
  }
}
