import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    border: `1px solid ${theme.fn.rgba(theme.colors.gray[4], 0.3)}`,
    borderSpacing: 0,
    borderCollapse: 'separate',
    borderRadius: '8px',
    overflow: 'hidden',

    ['thead']: {
      // backgroundColor: theme.colors.mint[0],
    },

    ['thead tr th']: {
      borderBottom: `1px solid ${theme.fn.rgba(theme.colors.gray[4], 0.3)}`,
    },

    ['tbody tr td']: {
      borderBottom: `1px solid ${theme.fn.rgba(theme.colors.gray[4], 0.3)}`,
    },

    // ['tr:last-child > td']: {
    //   borderBottom: 'none',
    // },
  },
}))
