import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Anchor, Box, Group, Loader } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import {
  CheckboxFieldController,
  PasswordInputController,
  SelectController,
  TextInputController,
} from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { authAPI } from '@/features/auth/api'
import { ROLE } from '@/features/users/types'
import { useFormSubmit } from '@/hooks'
import { NormalizeUtils, ValidationsUtils } from '@/utils'

export type FormValues = {
  first_name: string
  last_name: string
  title: string
  address: string
  phone: string
  password: string
  password_confirmation: string
  accept: boolean
  doctor_association?: string
  city: string
  specialist_id?: string
  email?: string
}

type IRole = {
  role: ROLE
  email: string
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
}

export const RegisterForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const [roles, setRoles] = useState([])
  const [role, setRole] = useState<IRole | 'error' | 'loading'>('error')

  const loadRoles = async () => {
    try {
      const { data } = await authAPI.specialistsRoles()

      const dataRoles = data?.data?.map((el: any) => ({ value: `${el?.id}`, label: el?.name }))

      setRoles(dataRoles)
    } catch (e) {}
  }

  useEffect(() => {
    loadRoles()
  }, [])

  const [searchParams, setSearchParams] = useSearchParams()

  const loadAuthInfo = async () => {
    setRole('loading')

    const token = searchParams.get('token')
    if (!token) {
      setRole('error')
    }

    try {
      const { data } = await authAPI.authInfo(token)
      setRole(data?.data)
      setValue('email', data?.data?.email || '')
      setValue('specialist_id', data?.data?.specialist_id || '')
      setValue('doctor_association', data?.data?.doctor_association || '')
    } catch (e) {
      setRole('error')
    }
  }

  useEffect(() => {
    loadAuthInfo()
  }, [searchParams])

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { isSubmitting },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      first_name: '',
      last_name: '',
      specialist_id: '',
      email: '',
      doctor_association: '',
      city: '',
      address: '',
      title: '',
      phone: '',
      password: '',
      password_confirmation: '',
      accept: false,
    },
    resolver: yupResolver(
      Yup.object().shape({
        first_name: Yup.string().max(255).required('field.error.required'),
        last_name: Yup.string().max(255).required('field.error.required'),
        address: Yup.string().max(500).required('field.error.required'),
        title: Yup.string().max(255).required('field.error.required'),
        doctor_association: Yup.string().max(500),
        city: Yup.string().max(255),
        specialist_id: Yup.string().max(500),
        phone: Yup.string()
          .test('phone', 'field.error.invalid', ValidationsUtils.phoneFormat)
          .required('field.error.required'),
        password: Yup.string()
          .required('field.error.required')
          .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
        password_confirmation: Yup.string()
          .required('field.error.required')
          .oneOf([Yup.ref('password'), null], 'field.error.password.no_match')
          .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
        accept: Yup.bool().oneOf([true], 'required'),
      })
    ),
  })

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (err) {}
  }

  const watchAccept = watch('accept')

  if (role === 'loading') {
    return (
      <Box my={'lg'}>
        <Group align={'center'} w={'100%'} position={'center'}>
          <Loader size="lg" />
        </Group>
      </Box>
    )
  }

  // if (role === 'error') {
  //   return (
  //     <Alert type={'error'} mb={'sm'}>
  //       {t('invalid_token')}
  //     </Alert>
  //   )
  // }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <TextInputController
          control={control}
          name={'email'}
          id={'email'}
          label={t('email')}
          disabled
          placeholder={t('email')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'first_name'}
          id={'first_name'}
          label={t('first_name')}
          placeholder={t('first_name')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'last_name'}
          id={'last_name'}
          label={t('last_name')}
          placeholder={t('last_name')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'title'}
          id={'title'}
          label={t('title')}
          placeholder={t('title')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'address'}
          id={'address'}
          label={t('address')}
          placeholder={t('address')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'city'}
          id={'city'}
          label={t('city')}
          placeholder={t('city')}
          mb={'md'}
        />

        {role !== 'error' && role?.role === ROLE.Doctor && (
          <TextInputController
            control={control}
            name={'doctor_association'}
            id={'doctor_association'}
            label={t('doctor_association')}
            placeholder={t('doctor_association')}
            disabled
            mb={'md'}
          />
        )}

        {role !== 'error' && role?.role === ROLE.Specialist && (
          <SelectController
            control={control}
            name={'specialist_id'}
            id={'specialist_id'}
            label={t('specialist')}
            placeholder={t('specialist')}
            data={roles?.map((el: any) => ({ ...el, label: t(`${el.label}`) })) || []}
            disabled
            mb={'md'}
          />
        )}

        <TextInputController
          control={control}
          name={'phone'}
          id={'phone'}
          label={t('phone')}
          placeholder={t('phone')}
          mb={'md'}
          normalize={NormalizeUtils.phone}
        />

        <PasswordInputController
          control={control}
          name={'password'}
          id={'password'}
          label={t('password')}
          placeholder={t('password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <PasswordInputController
          control={control}
          name={'password_confirmation'}
          id={'password_confirmation'}
          label={t('confirm_password')}
          placeholder={t('confirm_password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <CheckboxFieldController
          control={control}
          name={'accept'}
          id={'accept'}
          label={
            <>
              {t('register_terms_label')}{' '}
              <Anchor
                component={Link}
                to={'/terms'}
                className={'font-bold'}
                state={{ from: '/register' }}
              >
                {t('register_terms_text')}
              </Anchor>
            </>
          }
          mb={'md'}
        />

        <Button mt={'lg'} fullWidth type={'submit'} disabled={!watchAccept} loading={isSubmitting}>
          {t('sign_up')}
        </Button>
      </form>
    </>
  )
}
