import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { Chat as ChatComponent } from '../../components/Chats/Chat'

export const Chat = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'chat'} />

      <PageContent>
        <ChatComponent />
      </PageContent>
    </>
  )
}
