import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert, MegaTagTitle } from '@/components/Elements'
import { LoginContainer } from '@/features/auth/components/Login'
import { useQuery } from '@/hooks'

interface IAlert {
  type: string
  message: string
}

export const Login = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const query = useQuery()

  const [alert, setAlert] = useState<IAlert | null>(null)

  const setAlertMessage = (data: IAlert | null = null) => {
    setAlert(data)
  }

  const historyReplace = () => {
    navigate(
      {
        search: '',
      },
      { replace: true }
    )
  }

  useEffect(() => {
    if (query.get('verified') === '1') {
      setAlertMessage({
        message: 'email_verification.success',
        type: 'success',
      })

      historyReplace()
    }
  }, [])

  return (
    <>
      <MegaTagTitle title={'login'} />

      {alert?.message && (
        <Alert type={'success'} mb={'md'}>
          {t(alert.message)}
        </Alert>
      )}

      <LoginContainer />
    </>
  )
}
