import { createSlice } from '@reduxjs/toolkit'

interface IState {
  windowFocus: boolean
}

const initialState: IState = {
  windowFocus: true,
}

const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    windowFocus(state, action) {
      state.windowFocus = action.payload
    },
  },
})

/**
 * @namespace
 * @property {function} windowFocus
 */

export const appState = appStateSlice.actions

export default appStateSlice.reducer
